// src/components/Header.js
import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import '../styles/Header.css';
import { useAuth } from '../contexts/AuthContext';
import { AiOutlineHeart } from 'react-icons/ai';
import { FiUser } from 'react-icons/fi';
import { BsChatDots } from 'react-icons/bs';
import { MdKeyboardArrowDown, MdMenu, MdClose } from 'react-icons/md';
import { getChats } from '../utils/db';

const Header = () => {
  const { currentUser, logout } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const [savedCount, setSavedCount] = useState(0);
  const [unreadMessageCount, setUnreadMessageCount] = useState(0);
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [openDropdowns, setOpenDropdowns] = useState({});

  useEffect(() => {
    const savedCars = JSON.parse(localStorage.getItem('savedCars')) || {};
    setSavedCount(Object.keys(savedCars).length);

    if (currentUser) {
      fetchUnreadMessageCount();
    }
  }, [currentUser, location.pathname]);

  const fetchUnreadMessageCount = async () => {
    try {
      const chats = await getChats();
      const totalUnread = Array.isArray(chats)
        ? chats.reduce((sum, chat) => sum + (chat.unread_count || 0), 0)
        : 0;
      setUnreadMessageCount(totalUnread);
    } catch (error) {
      console.error('Error fetching unread message count:', error);
    }
  };

  const toggleMobileMenu = () => {
    setMobileMenuOpen(!mobileMenuOpen);
  };

  const handleDropdownToggle = (index) => {
    setOpenDropdowns(prev => ({
      ...prev,
      [index]: !prev[index]
    }));
  };

  const handleClickOutside = (event) => {
    // If the click is outside any open dropdown, close all dropdowns
    if (!event.target.closest('.dropdown')) {
      setOpenDropdowns({});
    }
  };

  useEffect(() => {
    if (Object.values(openDropdowns).some(isOpen => isOpen)) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [openDropdowns]);

  useEffect(() => {
    setOpenDropdowns({});
    setMobileMenuOpen(false);
  }, [location.pathname]);

  const navLinks = [
    {
      title: 'Зарууд',
      submenu: [
        { title: 'Зарах Зарууд', link: '/listings' },
        { title: 'Авах Зарууд', link: '/buyer-requests' },
      ],
    },
    {
      title: 'Зар байршуулах',
      submenu: [
        { title: 'Зарах', link: '/sell-my-car' },
        { title: 'Авах', link: '/request-car' },
      ],
    },
    {
      title: 'ToolsGPT',
      link: '/price-estimator',
      submenu: [
        { title: 'CarGPT', link: '/price-estimator' },
        { title: 'IncomeGPT', link: '/bank-analysis' },
        { title: 'LawGPT', link: '/LawGPT' },
      ],
    },
    // { title: 'Шинэ Машин', link: '/new-cars' },
  ];

  return (
    <header className="header">
      {/* Mobile Header */}
      <div className="mobile-header">
        <div className="mobile-logo">
          <Link to="/">softmax</Link>
        </div>
        <div className="mobile-menu-button">
          <button onClick={toggleMobileMenu} aria-label={mobileMenuOpen ? "Close Menu" : "Open Menu"}>
            {mobileMenuOpen ? <MdClose size={24} /> : <MdMenu size={24} />}
          </button>
        </div>
      </div>

      {/* Mobile Navigation Menu */}
      {mobileMenuOpen && (
        <div className="mobile-menu-overlay" onClick={toggleMobileMenu}>
          <div className="mobile-menu" onClick={(e) => e.stopPropagation()}>
            <nav aria-label="Mobile Navigation">
              <ul>
                {navLinks.map((item, index) => (
                  <li
                    key={index}
                    className={`dropdown ${item.submenu ? 'has-submenu' : ''} ${location.pathname.startsWith(item.link) ? 'active' : ''}`}
                  >
                    {item.submenu ? (
                      <>
                        <button
                          className="nav-link"
                          onClick={() => handleDropdownToggle(index)}
                          aria-haspopup="true"
                          aria-expanded={openDropdowns[index] || false}
                        >
                          {item.title} <MdKeyboardArrowDown />
                        </button>
                        {openDropdowns[index] && (
                          <div className="dropdown-menu dropdown-menu-open" role="menu">
                            <div className="dropdown-menu-content">
                              {item.submenu.map((subItem, subIndex) => (
                                <Link
                                  key={`${index}-${subIndex}`}
                                  to={subItem.link}
                                  className={`dropdown-item ${location.pathname === subItem.link ? 'active' : ''}`}
                                  onClick={() => {
                                    handleDropdownToggle(index);
                                    setMobileMenuOpen(false);
                                  }}
                                >
                                  {subItem.title}
                                </Link>
                              ))}
                            </div>
                          </div>
                        )}
                      </>
                    ) : (
                      <Link
                        to={item.link}
                        className={`nav-link ${location.pathname === item.link ? 'active' : ''}`}
                        aria-current={location.pathname === item.link ? 'page' : undefined}
                        onClick={() => setMobileMenuOpen(false)}
                      >
                        {item.title}
                      </Link>
                    )}
                  </li>
                ))}
              </ul>
            </nav>
            <div className="auth-buttons-mobile">
              {currentUser ? (
                <div className="icon-container">
                  <Link to="/saved" className="icon-link" aria-label={`Хадгалсан (${savedCount})`} onClick={() => setMobileMenuOpen(false)}>
                    <AiOutlineHeart size={24} />
                    <span>Хадгалсан ({savedCount})</span>
                  </Link>
                  <Link to="/chat" className="icon-link" aria-label={`Чат ${unreadMessageCount > 0 ? `(${unreadMessageCount})` : ''}`} onClick={() => setMobileMenuOpen(false)}>
                    <BsChatDots size={24} />
                    <span>Чат {unreadMessageCount > 0 && `(${unreadMessageCount})`}</span>
                  </Link>
                  <Link to="/account" className="icon-link" aria-label="User Account" onClick={() => setMobileMenuOpen(false)}>
                    <FiUser size={24} />
                    <span>Хаяг</span>
                  </Link>
                </div>
              ) : (
                <div className="mobile-auth-buttons">
                  <Link to="/login" className="mobile-sign-in" onClick={() => setMobileMenuOpen(false)}>
                    Нэвтрэх
                  </Link>
                  <Link to="/signup" className="mobile-sign-up" onClick={() => setMobileMenuOpen(false)}>
                    Бүртгүүлэх
                  </Link>
                </div>
              )}
            </div>
          </div>
        </div>
      )}

      {/* Desktop Header */}
      <div className="desktop-header">
        <div className="header-content">
          <div className="logo">
            <Link to="/">softmax</Link>
          </div>
          <nav aria-label="Main Navigation">
            <ul>
              {navLinks.map((item, index) => (
                <li
                  key={index}
                  className={`dropdown ${item.submenu ? 'has-submenu' : ''} ${location.pathname.startsWith(item.link) ? 'active' : ''}`}
                >
                  {item.submenu ? (
                    <>
                      <button
                        className="nav-link"
                        onClick={() => handleDropdownToggle(index)}
                        aria-haspopup="true"
                        aria-expanded={openDropdowns[index] || false}
                      >
                        {item.title} <MdKeyboardArrowDown />
                      </button>
                      {openDropdowns[index] && (
                        <div className="dropdown-menu dropdown-menu-open" role="menu">
                          <div className="dropdown-menu-content">
                            {item.submenu.map((subItem, subIndex) => (
                              <Link
                                key={`${index}-${subIndex}`}
                                to={subItem.link}
                                className={`dropdown-item ${location.pathname === subItem.link ? 'active' : ''}`}
                                onClick={() => {
                                  handleDropdownToggle(index);
                                }}
                              >
                                {subItem.title}
                              </Link>
                            ))}
                          </div>
                        </div>
                      )}
                    </>
                  ) : (
                    <Link
                      to={item.link}
                      className={`nav-link ${location.pathname === item.link ? 'active' : ''}`}
                      aria-current={location.pathname === item.link ? 'page' : undefined}
                    >
                      {item.title}
                    </Link>
                  )}
                </li>
              ))}
            </ul>
          </nav>
          <div className="auth-buttons">
            {currentUser ? (
              <div className="icon-container">
                <Link to="/saved" className="icon-link" aria-label={`Хадгалсан (${savedCount})`}>
                  <AiOutlineHeart size={24} />
                  <span>Хадгалсан ({savedCount})</span>
                </Link>
                <Link to="/chat" className="icon-link" aria-label={`Чат ${unreadMessageCount > 0 ? `(${unreadMessageCount})` : ''}`}>
                  <BsChatDots size={24} />
                  <span>Чат {unreadMessageCount > 0 && `(${unreadMessageCount})`}</span>
                </Link>
                <Link to="/account" className="icon-link" aria-label="User Account">
                  <FiUser size={24} />
                  <span>Хаяг</span>
                </Link>
              </div>
            ) : (
              <>
                <Link to="/login"><button className="sign-in">Нэвтрэх</button></Link>
                <Link to="/signup"><button className="sign-up">Бүртгүүлэх</button></Link>
              </>
            )}
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
