// src/components/PriceEstimator.js

import React, { useState, useEffect } from 'react';
import {
  TextField,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Typography,
  Box,
  Grid,
  Fade,
  Paper
} from '@mui/material';
import axios from 'axios';
import { brandsData } from '../data/predictioncarData'; // Import the brands and models data

// Define color options
const colorOptions = [
  'Хар', // Black
  'Цагаан', // White
  'Саарал', // Gray
  'Ногоон', // Green
  'Мөнгөлөг', // Light Blue
  'Улаан', // Red
  'Шар', // Yellow
  'Бор', // Brown
  'Бусад' // Other
];

const gearboxOptions = ['Автомат', 'Механик', 'Хагас Автомат'];

// Generate options from 0.0 to 10.0 with 0.1 increments
const generateNumericOptions = (start, end, step) => {
  const options = [];
  for (let i = start; i <= end; i += step) {
    options.push(i.toFixed(1));
  }
  return options;
};

const engineOptions = generateNumericOptions(0.0, 10.0, 0.1);

const PriceEstimator = () => {
  const currentYear = new Date().getFullYear();

  const [formData, setFormData] = useState({
    brand: '',
    model: '',
    year_made: '',
    imported_year: '',
    odometer: '',
    hurd: '',
    // Faux inputs
    Хөдөлгүүр: '1.5',
    Өнгө: '',
    'Хурдны хайрцаг': ''
  });

  const [models, setModels] = useState([]);
  const [predictedPrice, setPredictedPrice] = useState(null);
  const [error, setError] = useState('');
  const [isThinking, setIsThinking] = useState(false);

  // Update models based on selected brand
  useEffect(() => {
    if (formData.brand) {
      const sortedModels = [...brandsData[formData.brand]].sort();
      setModels(sortedModels);
      setFormData((prevData) => ({ ...prevData, model: '' }));
    } else {
      setModels([]);
      setFormData((prevData) => ({ ...prevData, model: '' }));
    }
  }, [formData.brand]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    setPredictedPrice(null); // Reset price when input changes
    setError('');
  };

  // Function to format price with commas
  const formatPrice = (price) => {
    if (typeof price !== 'number') return 'N/A';
    return price.toLocaleString();
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    // 1) First, track the button click
    try {
      await axios.post('/api/analytics/', {
        event_type: 'button_click',
        path: 'PriceEstimator:Predict',
        ip_address: null, 
      });
    } catch (err) {
      console.error('Error logging button click:', err);
    }
  
    if (!formData.year_made || !formData.imported_year) {
      setError('Please enter both Year Made and Imported Year.');
      return;
    }
  
    setIsThinking(true);
    setPredictedPrice(null);
    setError('');


    const payload = {
      brand: formData.brand,
      model: formData.model,
      year_made: formData.year_made,
      imported_year: formData.imported_year,
      odometer: formData.odometer,
      hurd: formData.hurd,
      'Хурдны хайрцаг': formData['Хурдны хайрцаг'],
      'Хөдөлгүүр': formData['Хөдөлгүүр'],
      'Өнгө': formData['Өнгө'],
    };

    try {
      const response = await axios.post('/api/predict-price/', payload, {
        baseURL: process.env.REACT_APP_API_URL || 'https://softmax.mn',
        headers: {
          'Content-Type': 'application/json'
        }
      });

      const price = response.data.predicted_price;

      if (price != null && typeof price === 'number') {
        setPredictedPrice(price);
      } else {
        throw new Error('Invalid price received from server.');
      }
    } catch (err) {
      console.error('Prediction error:', err);
      setError('Failed to predict price. Please check your inputs and try again.');
    } finally {
      setIsThinking(false);
    }
  };

  return (
    <Box sx={{ 
      maxWidth: 600, 
      mx: 'auto', 
      p: 3,
      '& *': {  // This applies Rubik font to all children
        fontFamily: 'Rubik, sans-serif'
      }
    }}>
      <Typography
        variant="h4"
        gutterBottom
        sx={{
          textAlign: 'center',
          fontWeight: 'bold',
          mb: 4,
          background: 'linear-gradient(to right, #0044cc, blue, #00aaff)',
          backgroundClip: 'text',
          WebkitBackgroundClip: 'text',
          color: 'transparent'
        }}
      >
        CarGPT
      </Typography>
      <form onSubmit={handleSubmit}>
        {/* Brand Selection */}
        <FormControl fullWidth required margin="normal">
          <InputLabel id="brand-label">Үйлдвэрлэгч</InputLabel>
          <Select
            labelId="brand-label"
            name="brand"
            value={formData.brand}
            label="Үйлдвэрлэгч"
            onChange={handleChange}
          >
            {Object.keys(brandsData).map((brand) => (
              <MenuItem key={brand} value={brand}>
                {brand}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        {/* Model Selection */}
        <FormControl fullWidth required margin="normal" disabled={!formData.brand}>
          <InputLabel id="model-label">Загвар</InputLabel>
          <Select
            labelId="model-label"
            name="model"
            value={formData.model}
            label="Загвар"
            onChange={handleChange}
          >
            {models.map((model) => (
              <MenuItem key={model} value={model}>
                {model}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        {/* Year Made and Imported Year Side by Side */}
        <Grid container spacing={2}>
          {/* Year Made */}
          <Grid item xs={12} sm={6}>
            <TextField
              label="Үйлдвэрлэсэн он"
              name="year_made"
              type="number"
              value={formData.year_made}
              onChange={handleChange}
              fullWidth
              required
              margin="normal"
              inputProps={{ min: 1900, max: currentYear }}
            />
          </Grid>

          {/* Imported Year */}
          <Grid item xs={12} sm={6}>
            <TextField
              label="Орж ирсэн он"
              name="imported_year"
              type="number"
              value={formData.imported_year}
              onChange={handleChange}
              fullWidth
              required
              margin="normal"
              inputProps={{ min: 1900, max: currentYear }}
            />
          </Grid>
        </Grid>

        {/* Odometer */}
        <TextField
          label="Гүйлт"
          name="odometer"
          type="number"
          value={formData.odometer}
          onChange={handleChange}
          fullWidth
          required
          margin="normal"
          inputProps={{ min: 0 }}
        />

        {/* Хурдны хайрцаг (Gearbox) - Faux Input with Select Options */}
        <FormControl fullWidth margin="normal">
          <InputLabel id="hurdny-hairtsag-label">Хурдны хайрцаг</InputLabel>
          <Select
            labelId="hurdny-hairtsag-label"
            name="Хурдны хайрцаг"
            value={formData['Хурдны хайрцаг']}
            label="Хурдны хайрцаг"
            onChange={handleChange}
          >
            {gearboxOptions.map((option) => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        {/* Хөдөлгүүр (Engine) */}
        <TextField
          label="Хөдөлгүүр багтаамж (Л)"
          name="Хөдөлгүүр"
          type="number"
          value={formData['Хөдөлгүүр']}
          onChange={handleChange}
          fullWidth
          margin="normal"
          inputProps={{ min: 0, step: 0.1 }}
        />

        {/* Өнгө (Color) - Faux Input with Select Options */}
        <FormControl fullWidth margin="normal">
          <InputLabel id="ongoo-label">Өнгө</InputLabel>
          <Select
            labelId="ongoo-label"
            name="Өнгө"
            value={formData['Өнгө']}
            label="Өнгө"
            onChange={handleChange}
          >
            {colorOptions.map((color) => (
              <MenuItem key={color} value={color}>
                {color}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        {/* Hurd */}
        <FormControl fullWidth required margin="normal">
          <InputLabel id="hurd-label">Хүрд</InputLabel>
          <Select
            labelId="hurd-label"
            name="hurd"
            value={formData.hurd}
            label="Хүрд"
            onChange={handleChange}
          >
            <MenuItem value="Буруу">Буруу</MenuItem>
            <MenuItem value="Зөв">Зөв</MenuItem>
            {/* Add other gearbox options if available */}
          </Select>
        </FormControl>

        {/* Button Container */}
        <Box sx={{ mt: 4, display: 'flex', justifyContent: 'center', minHeight: '48px', alignItems: 'center' }}>
          <Button
            variant="contained"
            type="submit"
            size="large"
            disabled={isThinking}
            sx={{
              background: 'linear-gradient(to right, #0044cc, blue, #00aaff)',
              color: 'white',
              boxShadow: '0 3px 5px 2px rgba(0, 68, 204, .3)',
              padding: '12px 30px',
              fontWeight: 900,
              fontSize: '1.1rem',
              '&:hover': {
                background: 'linear-gradient(to right, #003399, #0000cc, #0088cc)',
              }
            }}
          >
            {isThinking ? 'Thinking...' : 'Үнэ Тогтоох'}
          </Button>
        </Box>

        {/* Price Display */}
        <Box sx={{ mt: 2, textAlign: 'center' }}>
          <Fade in={predictedPrice !== null && !isThinking} timeout={1000}>
            <Paper elevation={0} sx={{ 
              p: 2,
              background: 'transparent'
            }}>
              <Typography variant="h6" sx={{ 
                color: '#666', 
                mb: 1,
                fontWeight: 600
              }}>
                Таны машины үнэлгээ
              </Typography>
              <Typography 
                variant="h4" 
                sx={{ 
                  fontWeight: 800,
                  color: '#000000'
                }}
              >
                {formatPrice(predictedPrice)} сая ₮
              </Typography>
            </Paper>
          </Fade>
        </Box>

        {/* Error Message */}
        {error && (
          <Typography color="error" sx={{ mt: 2, textAlign: 'center' }}>
            {error}
          </Typography>
        )}
      </form>
    </Box>
  );
};

export default PriceEstimator;
